$font-family-sans-serif:
  // Safari for OS X and iOS (San Francisco)
        -apple-system,
          // Chrome < 56 for OS X (San Francisco)
        BlinkMacSystemFont,
          // Windows
        "Segoe UI",
          // Android
        "Roboto",
          // Basic web fallback
        "Helvetica Neue", Arial, sans-serif,
          // Emoji fonts
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font: $font-family-sans-serif;

$grey-background: #54565e;