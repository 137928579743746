.navBar {
    background-color: black;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.navbar-toggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-bars {
    position: fixed;
    top: 0;
    left: 0;
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu{
    background-color: black;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -400px;
    transition: 850ms;
}

.nav-menu.active{
    left: 0px;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 8px 8px 16px;
    list-style: none;
    height: 80px;
}

.nav-text a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-text a hover {
    background-color: green;
}

.nav-menu-items {
    width: 100%;
}

span {
    margin-left: 16px;
}