@import '../../style-variables.scss';

.about {
  text-align: center;
  background-color: $grey-background;
  column-count: 2;
  display: grid;
  grid-template-columns: 1.0fr .25fr;
}

.top-right {
  position: fixed;
  top: 75px;
  right: 20px;
  display: grid;
  grid-template-columns: 1fr;
}

.photo-charles {
  max-height: 75px;
}