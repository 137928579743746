.header {
    max-height: 150px;
    text-align: center;
    display: inline-grid;
    grid-template-columns: auto 150px;
    position: sticky;
    top: 10px;
}

.logo {
    display: grid;
    max-width: 500px;
    width: 50%;
    max-height: 10vh;
}

.username {
    display: grid;
    width: 25%;
    font-family: "Courier New", serif;
    background-color: black;
    color: green;
}

.center-fit {
  max-width: 100%;
  max-height: 15vh;
  margin: auto;
}