@import './style-variables.scss';

.App {
  text-align: center;
  background-color: $grey-background;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: black;
}

.bookkeeping {
  display: flow;
  height: 50vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.login {
  display: flex;
}

.kcProvider {
  color: green;
}

@media screen and (min-width: 800px){

  h1 {
    font-size: calc(1.5rem + 3vw);
  }

  p {
    font-size: calc(1em + 1vw);
  }
}

@media screen and (max-width: 800px) {
  * {
    display: grid;
    grid-template-columns: 1.0fr;
  }

  img,
  picture,
  video {
    max-width: 100%;
  }

  h1 {
    font-size: calc(1.5rem + 3vw);
  }
}